import format from "date-fns/format";
import differenceInDays from "date-fns/differenceInDays";
import { Payment } from "../../../../generated/graphql";
import { PaymentItemBlock, PaymentItemElement } from "./PaymentItem.styles";
import { TODAY_DATE, isExternalPayment } from "../utils";
import { CombinedPaymentTypeMap } from "../maps";

interface PaymentItemProps {
  payment: Payment;
  isPersonal: boolean;
  isHistory?: boolean;
}

const formatDate = (date: string) => format(new Date(date), "dd.MM.yyyy");
const getDaysLeft = (endDate: string, startDate: string | Date) => {
  return differenceInDays(new Date(endDate), new Date(startDate));
};

const getPaymentStatus = (isActivated: boolean) => {
  return isActivated ? "Success" : "Pending";
};

export const PaymentItem = ({
  payment,
  isPersonal,
  isHistory = false,
}: PaymentItemProps) => {
  const {
    paymentDate,
    expiredDate,
    plan,
    paymentType,
    pupilsCount,
    teachersCount,
    total,
    isActivated,
  } = payment;

  const activePaymentContent = (
    <>
      <b>Подписка заканчивается через: </b>
      <span>{`${getDaysLeft(expiredDate, TODAY_DATE)}`}</span>
    </>
  );

  const historyPaymentContent = (
    <>
      <b>Длительность подписки: </b>
      <span>{`${getDaysLeft(expiredDate, paymentDate)} дней`}</span>
    </>
  );

  const paymentStatusContent = (
    <>
      {isExternalPayment(paymentType) ? (
        <>
          <b>Статус: </b> <span>{getPaymentStatus(isActivated)}</span>
        </>
      ) : null}
    </>
  );

  return (
    <PaymentItemBlock>
      <PaymentItemElement>
        <b>Предоставленный доступ: </b> <span>{formatDate(paymentDate)}</span>
      </PaymentItemElement>
      <PaymentItemElement>
        <b>Подписка доступна до: </b> <span>{formatDate(expiredDate)}</span>
      </PaymentItemElement>
      <PaymentItemElement>
        {isHistory ? historyPaymentContent : activePaymentContent}
      </PaymentItemElement>
      <PaymentItemElement>
        <b>Тариф: </b> <span>{plan?.description || plan?.name}</span>
      </PaymentItemElement>
      <PaymentItemElement>
        <b>Количество месяцев: </b> <span>{`${plan?.monthsCount} мес.`}</span>
      </PaymentItemElement>
      <PaymentItemElement>
        <b>Тип платежа: </b>{" "}
        <span>{CombinedPaymentTypeMap.get(paymentType) || paymentType}</span>
      </PaymentItemElement>
      <PaymentItemElement>
        {!isPersonal && (
          <>
            <b>Количество учеников: </b> <span>{pupilsCount}</span>
          </>
        )}
      </PaymentItemElement>
      <PaymentItemElement>
        {!isPersonal && (
          <>
            <b>Количество учителей: </b> <span>{teachersCount}</span>
          </>
        )}
      </PaymentItemElement>
      <PaymentItemElement>{paymentStatusContent}</PaymentItemElement>
      <PaymentItemElement>
        <b>Стоимость: </b>{" "}
        <span>{`${total} ${
          process.env.REACT_APP_CURRENT_LOCALE === "en" ? "$" : "грн"
        }`}</span>
      </PaymentItemElement>
    </PaymentItemBlock>
  );
};
