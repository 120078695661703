import React from "react";
import {
  PaymentDataV3,
  SubscriptionPlan,
  useGetAdminPanelSubscriptionsPaymentTypeQuery,
} from "../../../../generated/graphql";
import { SubscriptionFormValues, validateForm } from "./utils";
import { Field, Form } from "react-final-form";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { ButtonCustom, ModalInput } from "../../../shared/Style/Style";
import { commonPaymentTypeMap, subscriptionPlansMonths } from "../maps";
import { costText } from "../../constants";

type CreatePaymentDataV3 = {
  data: Partial<PaymentDataV3>;
};

interface SubscriptionFormPersonalSchoolProps {
  subscriptionPlans: SubscriptionPlan[];
  disableSubmit: boolean;
  createPayment(variables: CreatePaymentDataV3): void;
}

const formInitialValues: SubscriptionFormValues = {
  paymentDate: "",
  paymentTypeId: "",
  planId: "",
  total: "",
  validity: "",
};

export const SubscriptionFormPersonalSchool = ({
  subscriptionPlans,
  disableSubmit,
  createPayment,
}: SubscriptionFormPersonalSchoolProps) => {
  const { data: paymentTypeData } =
    useGetAdminPanelSubscriptionsPaymentTypeQuery();

  const paymentTypes =
    paymentTypeData?.getAdminPanelSubscriptionsPaymentType ?? [];

  const onFormValidation = (values: SubscriptionFormValues) =>
    validateForm(values, true);

  const onFormSubmit = (values: SubscriptionFormValues) => {
    const { paymentDate, paymentTypeId, planId, total } = values;

    if (!total || isNaN(Number(total))) return;

    const dataPaymentForPersonalSchool = {
      paymentDate: new Date(paymentDate),
      paymentTypeId,
      subscriptionPlanId: planId,
      total: Number(total) || 0,
    };

    createPayment({
      data: {
        ...dataPaymentForPersonalSchool,
      },
    });
  };

  return (
    <Form
      initialValues={formInitialValues}
      validate={onFormValidation}
      onSubmit={onFormSubmit}
    >
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <Grid container justifyContent="flex-start" spacing={2}>
            <Grid xs={6} item>
              <Field name="paymentDate">
                {({ input, meta }) => (
                  <DatePicker
                    error={meta.error && meta.touched}
                    helperText={meta.touched && meta.error}
                    fullWidth
                    variant="inline"
                    format="dd.MM.yyyy"
                    label="Дата платежа"
                    inputVariant="filled"
                    value={input.value || null}
                    onChange={input.onChange}
                  />
                )}
              </Field>
            </Grid>

            <Grid xs={6} item>
              <Field name="validity">
                {({ input, meta }) => (
                  <ModalInput
                    label="Кол-во месяцев"
                    error={meta.error && meta.touched}
                    helperText={meta.touched && meta.error}
                    variant="filled"
                    type="text"
                    disabled
                    {...input}
                  />
                )}
              </Field>
            </Grid>

            <Grid xs={6} item>
              <Field name="planId">
                {({ input, meta }) => (
                  <FormControl
                    variant="filled"
                    error={meta.error && meta.touched}
                    fullWidth
                  >
                    <InputLabel>Тариф</InputLabel>
                    <Select
                      fullWidth
                      value={input.value}
                      onChange={(e) => {
                        const selectedPlanId = e.target.value;
                        input.onChange(selectedPlanId);
                        const selectedPlan =
                          subscriptionPlans.find(
                            (plan) => plan.id === selectedPlanId
                          )?.name || "";
                        const durationInMonths =
                          subscriptionPlansMonths[selectedPlan]
                            ?.durationInMonths || "";
                        form.change("validity", durationInMonths);
                      }}
                    >
                      {subscriptionPlans.map(({ id, name, description }) => (
                        <MenuItem key={id} value={id}>
                          {`${description ?? name} ${
                            subscriptionPlansMonths[name]?.label || ""
                          }`}
                        </MenuItem>
                      ))}
                    </Select>
                    {meta.error && meta.touched ? (
                      <FormHelperText>{meta.error}</FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              </Field>
            </Grid>

            <Grid xs={6} item>
              <Field name="paymentTypeId">
                {({ input, meta }) => (
                  <FormControl
                    variant="filled"
                    error={meta.error && meta.touched}
                    fullWidth
                  >
                    <InputLabel>Тип платежа</InputLabel>
                    <Select
                      fullWidth
                      value={input.value.id}
                      onChange={(e) => {
                        input.onChange(e.target.value);
                      }}
                    >
                      {paymentTypes.map(({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {commonPaymentTypeMap[name]}
                        </MenuItem>
                      ))}
                    </Select>
                    {meta.error && meta.touched ? (
                      <FormHelperText>{meta.error}</FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              </Field>
            </Grid>

            <Grid xs={6} item>
              <Field name="total">
                {({ input, meta }) => (
                  <ModalInput
                    label={costText}
                    helperText={meta.touched && meta.error}
                    variant="filled"
                    type="text"
                    {...input}
                  />
                )}
              </Field>
            </Grid>

            <Grid xs={12} item>
              <ButtonCustom type="submit" disabled={disableSubmit} width="200">
                Оформить подписку
              </ButtonCustom>
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  );
};
