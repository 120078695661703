import {
  PaymentType,
  SubscriptionPlanNameEnum,
} from "../../../generated/graphql";

export enum SchoolPaymentsEnum {
  subscriptionPlans = "subscriptionPlans",
  paymentsHistory = "paymentsHistory",
  createPayment = "createPayment",
  activatePayment = "activatePayment",
  deletePayment = "deletePayment",
}

export const CustomErrorMessagesMap = new Map<SchoolPaymentsEnum, string>([
  [SchoolPaymentsEnum.subscriptionPlans, "Ошибка получения планов подписки"],
  [SchoolPaymentsEnum.paymentsHistory, "Ошибка получения истории платежей"],
  [SchoolPaymentsEnum.createPayment, "Ошибка создания платежа"],
  [SchoolPaymentsEnum.activatePayment, "Ошибка активации платежа"],
  [SchoolPaymentsEnum.deletePayment, "Ошибка удаления платежа"],
]);

export const NO_MANAGER_ERROR =
  "Failed to create subscription plan payment from admin panel You are not manager of this school";

export const GqlErrorMessagesMap = new Map<string, string>([
  [
    "You are not permitted for this action",
    "У вас нет прав на выполнение данного действия",
  ],
  [
    NO_MANAGER_ERROR,
    "Ошибка создания платежа. Только менеджер школы может оформить подписку.",
  ],
]);

export const commonPaymentTypeMap = {
  [PaymentType.Manual]: "Безнальная (ручная)",
  [PaymentType.Promotion]: "Маркетинговая акция",
  [PaymentType.Technical]: "Технический",
};

export const PersonalPaymentTypeMap = new Map<PaymentType, string>([
  [PaymentType.Fondy, "Fondy"],
  [PaymentType.Stripe, "Stripe"],
]);

export const CombinedPaymentTypeMap = new Map<PaymentType, string>([
  [PaymentType.Fondy, "Fondy"],
  [PaymentType.Stripe, "Stripe"],
  [PaymentType.Manual, "Безнальная (ручная)"],
  [PaymentType.Promotion, "Маркетинговая акция"],
  [PaymentType.Technical, "Технический"],
]);

export const subscriptionPlansMonths = {
  [SubscriptionPlanNameEnum.TeacherOne]: {
    label: "(1 мес.)",
    durationInMonths: "1",
  },
  [SubscriptionPlanNameEnum.PupilOne]: {
    label: "(1 мес.)",
    durationInMonths: "1",
  },
  [SubscriptionPlanNameEnum.ParentOne]: {
    label: "(1 мес.)",
    durationInMonths: "1",
  },
  [SubscriptionPlanNameEnum.TeacherPlusOne]: {
    label: "(1 мес.)",
    durationInMonths: "1",
  },

  [SubscriptionPlanNameEnum.TeacherSix]: {
    label: "(6 мес.)",
    durationInMonths: "6",
  },
  [SubscriptionPlanNameEnum.PupilSix]: {
    label: "(6 мес.)",
    durationInMonths: "6",
  },
  [SubscriptionPlanNameEnum.ParentSix]: {
    label: "(6 мес.)",
    durationInMonths: "6",
  },
  [SubscriptionPlanNameEnum.TeacherPlusSix]: {
    label: "(6 мес.)",
    durationInMonths: "6",
  },

  [SubscriptionPlanNameEnum.TeacherTwelve]: {
    label: "(12 мес.)",
    durationInMonths: "12",
  },
  [SubscriptionPlanNameEnum.PupilTwelve]: {
    label: "(12 мес.)",
    durationInMonths: "12",
  },
  [SubscriptionPlanNameEnum.ParentTwelve]: {
    label: "(12 мес.)",
    durationInMonths: "12",
  },
  [SubscriptionPlanNameEnum.TeacherPlusTwelve]: {
    label: "(12 мес.)",
    durationInMonths: "12",
  },
};
